import React, { Component } from "react";
import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBL1z7w9dw8TDJzxdJWnexCcjdZ4LgbHms",
    authDomain: "abhibalijepalli-437fd.firebaseapp.com",
    projectId: "abhibalijepalli-437fd",
    storageBucket: "abhibalijepalli-437fd.firebasestorage.app",
    messagingSenderId: "831593679835",
    appId: "1:831593679835:web:b7570819d33b571ae9f5fe",
    measurementId: "G-31J4DVJNXT"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);


class Art extends Component {
    constructor(props) {
        super(props);
        this.state = {
            artImages: [],
            artConfig: this.props.art
        };
    }

    componentDidMount() {
        const artRef = ref(storage, "/");

        listAll(artRef)
            .then((res) => {
                const urlPromises = res.items.map((itemRef) =>
                    getDownloadURL(itemRef).then((url) => ({
                        url,
                        title: itemRef.name,
                    }))
                );
                return Promise.all(urlPromises);
            })
            .then((artImages) => {
                this.setState({ artImages });
            })
            .catch((error) => {
                console.error("Error fetching art images:", error);
            });
    }

    render() {
        return (
            <section id="art" className="pb-5">
                <div className="col-md-12 mx-auto">
                    <div className="col-md-12">
                        <h1
                            className="section-title"
                            style={{ color: "black", paddingBottom: "20px" }}
                        >
                            <span className="text-black" style={{ textAlign: "center" }}>
                                Here's some of my art
                            </span>
                        </h1>
                    </div>
                </div>

                <div className="col-md-10 mx-auto">
                    <div className="row">
                        {this.state.artImages.map((art, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div className="art-card">
                                    <img
                                        src={art.url}
                                        alt={art.title}
                                        className="img-fluid"
                                        onClick={() => window.open(art.url)}
                                    />
                                    <div className="overlay">{art.title}</div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default Art;